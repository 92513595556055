export default {
  overline: 'Übersicht',
  headline: 'Stellschrauben',
  text: 'Für den Umbau der Stromerzeugung in einer dezentralen oder zentralen Welt betrachten wir vier  Stellschrauben im Energiesystem.',
  items: [
    {
      headline: 'Technologiemix',
      subline: 'Wie können Erzeugungstechnologien kombiniert werden',
      link: '/screw?index=0',
    },
    {
      headline: 'Geografische Verteilung',
      subline: 'Wo findet die Stromerzeugung statt',
      link: '/screw?index=1',
    },
    {
      headline: 'Strompreis',
      subline: 'Wie lässt sich der Strompreis anpassen',
      link: '/screw?index=2',
    },
    {
      headline: 'Netzausbau',
      subline: 'Wie und wo wird der Strom erzeugt und verbraucht',
      link: '/screw?index=3',
    }
  ]
}