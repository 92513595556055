export default {
  button: 'Weiter',
  items: [
    {
      imageId: 'windmills',
      title: 'Energiewelten',
      text: 'Zwei mögliche Energiewelten haben wir im Ariadne-Projekt für Sie angelegt. Sie stellen Extreme für die Versorgung mit vollständig <a class="link" data-index="erneuerbare-energien-strom-quellen">erneuerbarer Energie</a> dar. <br /> <br /> Zwischen diesen Extremen wollen wir mit Ihnen die Energieversorgung der Zukunft diskutieren.',
      subline: '',
    },
    {
      imageId: 'woman-config-empty',
      title: 'Stellschrauben',
      text: 'Die eingesetzen <a class="link" data-index="politische-instrumente">politischen Instrumente</a> - wir nennen sie hier Stellschrauben - erschaffen neue alternative Welten zwischen diesen beiden Extremen. ',
      subline: '',
    },
    {
      imageId: 'woman-forrest',
      title: 'Herausforderungen',
      text: 'Jede Stellschraube bringt unterschiedliche Herausforderungen und Konsequenzen mit sich. Manche schließen sich aus. Andere ergänzen sich. Manche können die Nachteile anderer durch eigene Vorteile ausgleichen.<br /> Wie man an diesen Stellschrauben drehen kann, wollen wir mit Ihnen diskutieren. ',
      subline: 'Beim nächsten mal nicht mehr anzeigen',
    },
  ]
}
