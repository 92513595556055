export default {
  itemsLeft: [
    { name: 'Start', iconLeftId: 'house', route: '/home' },
    {
      name: 'Welten', iconLeftId: 'globe', route: '/world'
    },
    {
      name: 'Stellschrauben', iconLeftId: 'wrench', iconRightId: 'arrowDown', items: [
        {
          name: 'Übersicht',
          route: '/screw-overview',
        },
        {
          name: 'Technologiemix',
          route: '/screw?index=0',
        },
        {
          name: 'Geografische Verteilung',
          route: '/screw?index=1',
        },
        {
          name: 'Strompreis',
          route: '/screw?index=2',
        },
        {
          name: 'Netzausbau',
          route: '/screw?index=3',
        }
      ]
    },
    {
      name: 'Herausforderungen', iconLeftId: 'thunder', iconRightId: 'arrowDown', items: [
        {
          name: 'Übersicht',
          route: '/condition-overview',
        },
        {
          name: 'Beteiligung & Konflikte',
          route: '/condition?index=0',
        },
        {
          name: 'Verteilungsgerechtigkeit',
          route: '/condition?index=1',
        },
        {
          name: 'Zugangsmöglichkeiten',
          route: '/condition?index=2',
        },
      ]
    },
  ],
  itemsRight: [
    {
      name: '', iconLeftId: 'glossar', route: 'glossar'
    },
  ]
}