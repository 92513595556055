export default {
  items: [
    {
      id: 'abstandsregelung',
      title: 'Abstandsregelung',
      text: 'Abstandsregelungen beziehen sich hier auf die Abstände, die Windräder zu Wohnsiedlungen haben müssen. Der Bund hat 2018 einen Mindestabstand von bis zu 1000 m zu Wohnsiedlungen empfohlen. Details für diesen Abstand regeln die Bundesländer selbst. ',
    },
    {
      id: 'ausschreibungen',
      title: 'Ausschreibungen',
      text: 'Das <strong>Erneuerbare-Energien-Gesetz (EEG)</strong> 2017 war Grundlage für die Förderung von neuen Windkraft- oder Solaranlagen im Ausschreibungs- oder Auktionsverfahren. Damit wird die finanzielle Höhe der Förderung von Erneuerbaren-Energien-Anlagen nicht mehr staatlich festgelegt, sondern durch die Bundesnetzagentur mittels Ausschreibung eines bestimmten Volumens an EE-Anlagen wettbewerblich ermittelt. Grundsätzlich gilt: Wer die Produktion von Strom mit Biogas, Wind oder Sonne unter Inanspruchnahme der geringsten staatlichen Förderung anbietet, hat am ehesten die Chance, den Zuschlag für die EEG-Förderung zu erhalten. Für Solaranlagen auf kleinen Dächern besteht aber weiterhin die staatlich festgelegte Einspeisevergütung.',
    },
    {
      id: 'energie-genossenschaft',
      title: 'Energie-Genossenschaft',
      text: 'Ein Zusammenschluss von Bürger:innen in der Rechtsform einer Genossenschaft, die zumeist das Ziel einer dezentralen, konzernunabhängigen und ökologischen Energiegewinnung verfolgt. Sie ist eine Form der Bürgerbeteiligung, vorwiegend auf kommunaler oder regionaler Ebene, und bietet die Möglichkeit, an der Energiewende aktiv mitzuwirken. Sie bietet darüber hinaus auch Anlage- und Investitionsmöglichkeiten in lokale und regionale Energieprojekte.',
    },
    {
      id: 'co2-emissionen',
      title: 'CO\u2082-Emissionen',
      text: 'CO\u2082- oder Kohlenstoffdioxidemissionen entstehen durch die Verbrennung kohlenstoffhaltiger Materialen, wie Kohle, Benzin, Erdgas oder Flüssiggas. Sie tragen wesentlich zum <strong>Treibhausgaseffekt</strong> und somit zur globalen Klimaerwärmung bei. Es gibt weitere Treibhausgase, CO\u2082-Emissionen bilden jedoch den größten Anteil. Bei Biomasseverbrennung hängt die Klimawirkung von der Nachhaltigkeit der Nutzung ab. ',
    },
    {
      id: 'co2-preis',
      title: 'CO\u2082-Preis',
      text: 'Der CO\u2082-Preis, auch Kohlenstoffpreis genannt, ist ein Preis, der pro ausgestoßener Tonne  CO\u2082 erhoben wird. Er gehört zu den sogenannten marktwirtschaftlichen Klimapolitikinstrumenten. Der  CO\u2082-Preis macht fossile Energieträger teurer. Davon profitieren erneuerbare Energien im Wettbewerb mit der fossilen Energie ebenso wie Innovationen, die beispielsweise die Energieeffizienz steigern, aber noch zu kostenintensiv sind. Der  CO\u2082-Preis kann über eine Steuer eingeführt werden oder über ein Emissionshandelssystem. Zentral ist bei beiden Instrumenten, dass der Preis hoch genug sein muss, um eine Lenkungswirkung weg von den fossilen Energieträgern zu entfalten. Rückverteilung: Außerdem ist wichtig, wie mit den zusätzlichen Einnahmen des Staates umgegangen wird. Entweder werden dafür andere Steuern (bspw. Die Stromsteuer) gesenkt oder die Einnahmen aus dem  CO\u2082-Preis werden an die Bürger:innen zurückgegeben, etwa durch eine direkte Rückzahlung.',
    },
    {
      id: 'digitalisierung-des-stromnetzes',
      title: 'Digitalisierung des Stromnetzes',
      text: 'Die Digitalisierung des Stromnetzes bezeichnet den Einsatz von intelligenten IT-Systemen, um Stromerzeugung und -verbrauch besser analysieren und steuern zu können. Dazu zählen auch sog. <strong>Smart-Meter</strong>, die als digitale und internetfähige Stromzähler Verbrauchsdaten bis zur Haushaltsebene sichtbar und nutzbar machen können. ',
    },
    {
      id: 'ee-analagen',
      title: 'EE-Anlagen',
      text: 'EE-Anlagen ist eine Abkürzung für Erneuerbare-Energien-Anlagen, also Kraftwerke, die Strom aus erneuerbarer Energiequellen herstellen: Dazu zählen bspw. Wasserkraftwerke, Biogasanlagen, <strong>Photovoltaik- oder Windkraftanlagen.</strong>',
    },
    {
      id: 'erneuerbare-energien-gesetz',
      title: 'EEG – Erneuerbare- Energien-Gesetz',
      text: 'Das Erneuerbare-Energien-Gesetz wurde im Jahr 2000 verabschiedet und hat die Aufgabe, den Ausbau der erneuerbaren Energien in Deutschland voranzutreiben und zu finanzieren. Es wurde seit seiner Verabschiedung mehrmals angepasst. Die Fassung von 2017 führte das heute auch im EEG 2021 noch gültige <strong>Ausschreibungsverfahren</strong> für EE-Anlagen ein. ',
    },
    {
      id: 'eeg-umlage',
      title: 'EEG-Umlage',
      text: 'Die EEG-Umlage dient der Finanzierung des Ausbaus erneuerbarer Energien und wurde mit dem EEG eingeführt.  Die im EEG eingeführten Investitionsanreize für <strong>Erneuerbare-Energien-Anlagen</strong> werden wesentlich durch die EEG-Umlage gegenfinanziert. Betreiber:innen von Erneuerbaren-Energien-Anlagen bekommen eine feste Vergütung pro kWh produziertem Strom. Falls diese Vergütung höher ist als der monatliche mittlere Preis an der Strombörse, wird den Betreiber:innen von erneuerbaren Energien der Differenzbetrag (zugesicherte Vergütung – aktueller Börsenpreis) von den Übertragungsnetzbetreibern erstattet. Die <strong>Übertragungsnetzbetreiber</strong> wiederum legen diese Erstattung auf die Stromkunden in Deutschland auf ein Jahr gemittelt um. Die Stromkund:innen zahlen also die EEG-Umlage mit.',
    },
    {
      id: 'eigenverbrauchsregelung',
      title: 'Eigenverbrauchsregelung',
      text: 'Diese Regel bildet eine Ausnahme bei der Zahlung der <strong>EEG-Umlage</strong> für sogenannte Prosumer („Producer“ und „Consumer“), also Verbraucher der eigenen Erzeugung. Seit der EEG-Novelle von 2021 muss auf den Eigenverbrauch des Stroms aus der eigenen Solaranlage mit einer Leistung von bis zu 30 kWp („<strong>Kilowatt Peak</strong>“) keine EEG-Umlage gezahlt werden. Zuvor lag diese Grenze bei 10 kWp. Ein Einfamilienhaus kann mit einer Solaranlage mit einer Leistung von 10 kWp bei Sonnenschein sicher versorgt werden. ',
    },
    {
      id: 'emittent',
      title: 'Emittent',
      text: 'Als Emittent wird die Quelle von Emissionen bezeichnet. Emission bedeutet „Ausstoß“ und Emissionen sind im umwelttechnischen Sinne Luftverunreinigungen, Geräusche, Erschütterungen, Licht, Wärme, Strahlen und ähnliche Umwelteinwirkungen. Häufig sind dabei Treibhausgase wie CO\u2082, Methan oder Stickoxide gemeint. ',
    },
    {
      id: 'endenergieverbrauch',
      title: 'Endenergieverbrauch',
      text: 'Endenergie ist die Energie, die aus Primärenergieträgern wie z.B. Braunkohlen, Steinkohlen, Erdöl, Erdgas, Wasser oder Wind durch Umwandlung gewonnen wird. Dabei wird die Primärenergie in eine Form umgewandelt, die Verbraucher:innen nutzen können, z.B. Strom, Wärme oder Kraftstoffe. Energieverluste, zum Beispiel bei der Umwandlung in Kraftstoffe, werden dabei ausgenommen. Der Endenergieverbrauch meint also den Gesamtverbrauch von Energie in den für die Nutzung von Verbraucher:innen umgewandelten Formen. ',
    },
    {
      id: 'energieberater-in',
      title: 'Energieberater:in',
      text: 'Energieberater:innen sind Expert:innen für Energieeffizienz. Sie unterstützen typischerweise bei der energetischen Sanierung von Gebäuden, das heißt bei der Renovierung von Energieversorgung und/oder Dämmung. Sie prüfen dabei zunächst den Bestand und erstellen dann einen Sanierungsplan. Energieberater:innen werden durch die dena (Deutsche Energie Agentur) gelistet, sofern sie bestimmte Qualitätskriterien erfüllen. Die Zusammenarbeit mit solchen gelisteten Berater:innen ist oft die Voraussetzung für die Beantragung von öffentlichen Fördergeldern bei Sanierung.',
    },
    {
      id: 'energieintensive-industrie',
      title: 'Energieintensive Industrie',
      text: 'Als energieintensive Industrie werden Unternehmen zusammengefasst, die für ihre Produktion hohe Mengen an Energie im Verhältnis zu ihrem Umsatz benötigen. Dazu zählen bspw. Unternehmen in den Sektoren der Stahlerzeugung, Grundstoffchemie, Aluminiumindustrie, Zement- und Glasherstellung oder der Papierherstellung. Zum Erhalt der internationalen Wettbewerbsfähigkeit kommen diesen Unternehmen mitunter Ausnahmen zugute: So sind sie je nach Höhe des Stromverbrauchs von der EEG-Umlage befreit. Im Ergebnis führt das dazu, dass mehr als 2000 energieintensive Unternehmen etwa 18 Prozent des Stroms verbrauchen, aber an der Finanzierung der Energiewende über die EEG-Umlage nur zu 0,3 Prozent beteiligt sind. Auf der anderen Seite steigt für die restlichen Stromkund:innen die <strong>EEG-Umlage</strong>, da dieser Fehlbetrag durch sie finanziert werden muss.,',
    },
    {
      id: 'energiemix',
      title: 'Energiemix',
      text: 'Der Energiemix gibt an, zu welchen Anteilen die unterschiedlichen Energieträger im gesamten Energieverbrauch genutzt werden. Das Benzin für Autos wird also ebenso berücksichtigt wie das Erdgas für Heizungen. ',
      image: 'https://crp-infotec.de/wp-content/uploads/d-energiemix.gif',
    },
    {
      id: 'energiewende',
      title: 'Energiewende',
      text: 'Die Energiewende meint im weitesten Sinne die Abkehr von fossilen Energieträgern, wie Kohle, Öl und Atomenergie, hin zu erneuerbaren Energien, wie Strom aus Sonnen- oder Windenergie. Damit geht eine Umwandlung des gesamten Energiesystems einher.',
    },
    {
      id: 'erneuerbare-energien-strom-quellen',
      title: 'Erneuerbare Energien/ erneuerbarer Strom/ erneuerbare Quellen',
      text: 'Unter erneuerbaren Energien, auch regenerative Energien genannt, versteht man Energieträger oder -quellen, die unendlich zur Verfügung stehen beziehungsweise in kürzerer Zeit wieder nachwachsen können – im Gegenteil zu <strong>fossilen Energieträgern</strong> wie Kohle oder Erdgas. Zu erneuerbaren Energieträgern zählen Wasserkraft, Solar- und Windenergie, Biomasse sowie Geothermie. Erneuerbarer Strom wird aus erneuerbaren Energieträgern hergestellt.',
    },
    {
      id: 'ertragreiche-standorte',
      title: 'Ertragreiche Standorte',
      text: 'Der Begriff bezieht sich auf die Verfügbarkeit von Sonne und/oder Wind an einem Ort oder einer Region: Je mehr Wind oder Sonne an einem Ort/in einer Region verfügbar sind desto ertragreicher gilt der Standort.',
    },
    {
      id: 'flexibilisierung-des-stromverbrauchs',
      title: 'Flexibilisierung des Stromverbrauchs',
      text: 'Bedeutet die Anpassung des Stromverbrauches an die Stromerzeugung durch die flexible Steuerung stromverbrauchender Maschinen. Strom wird also von Industrieunternehmen oder privaten Haushalten dann entnommen, wenn gerade viel Strom erzeugt wird bzw. im Netz ist, weil die Sonne scheint oder der Wind weht. Dies kann sowohl auf Basis des Strompreises der Strombörse erfolgen als auch bei Engpässen im Stromnetz oder für eine Eigenstromversorgung der Solaranlage auf dem Dach.',
    },
    {
      id: 'fossile-energieträger',
      title: 'Fossile Energieträger',
      text: 'Fossile Energieträger sind solche Energieträger, die zu vor langer Zeit beim Abbau von toten Pflanzen und Tieren entstanden sind. Hierzu gehören insbesondere Brennstoffe wie Kohle, Erdöl und Erdgas (auch Schiefergas), aber auch Torf. Alle fossilen Energieträger sind mehr oder weniger kohlenstoffhaltig (am meisten Kohle, am wenigsten Erdgas), so dass bei ihrer Verbrennung Kohlendioxid (CO\u2082) freigesetzt wird. Da die Abgase bisher fast immer in die Atmosphäre entlassen werden, steigt der CO\u2082-Gehalt der Atmosphäre Jahr für Jahr, und dies wird zunehmende Klimaveränderungen zur Folge haben.',
    },
    {
      id: 'fossile-subventionen',
      title: 'Fossile Subventionen',
      text: 'Fossile Subventionen bezeichnen umgangssprachlich steuerliche Vergünstigungen und Finanzhilfen für fossile Energieträger. Bei der Stromerzeugung meint das in Deutschland vor allem finanzielle Förderung für Steinkohle, Braunkohle und Atomenergie. Bis 2014 wurden seit 1970 in die Steinkohle 327 Mrd. EUR investiert, 219 Mrd. EUR in die Atomenergie und 95 Mrd. EUR in die Braunkohle.  Dem gegenüber stehen im gleichen Zeitraum insgesamt 102 Mrd. EUR staatliche Förderungen für erneuerbare Energiequellen. Diese Förderung fossiler Energiequellen umfasst noch nicht die für den Kohleausstieg seitdem zusätzlich beschlossenen Kompensations- und Strukturhilfezahlungen. Auch die Umwelt- und Klimakosten, die von der Allgemeinheit getragen werden, und die Kosten der Atommüllendlagerung sind davon ausgenommen. ',
    },
    {
      id: 'fossile-umlage',
      title: 'Fossile Umlage',
      text: 'Auch fossile Energiequellen werden in Deutschland mit Steuergeldern subventioniert oder durch Steuererleichterungen unterstützt – entweder durch direkte Förderungen oder indirekt dadurch, dass Kosten aus Umwelt- und Klimaschäden nicht von den Verursachern getragen werden müssen. Wenn man diese, ähnlich wie mit der EEG-Umlage, auf eine Kilowattstunde herunterrechnen würde, ergäbe sich ein etwa 11 Cent höherer Strompreis – pro Kilowattstunde!',
    },
    {
      id: 'große-energieerzeuger',
      title: 'Große Energieerzeuger',
      text: 'Die großen Energieerzeuger in Deutschland sind RWE, E.ON, Vattenfall und EnBW. 2019 hatten diese vier Unternehmen einen Marktanteil von 57 Prozent. Allerdings ist dieser im Vergleich zu den Vorjahren geschrumpft, da sich auf dem Markt immer mehr Anbieter etablieren. ',
    },
    {
      id: 'grüne-investments',
      title: 'Grüne Investments',
      text: 'Ein grünes Investment ist eine ökologische Geldanlage. Es kann in Aktien, in grüne Fonds oder direkt in ökologische Projekte getätigt werden.',
    },
    {
      id: 'heimspeicher',
      title: 'Heimspeicher',
      text: 'Heimspeicher sind Einrichtungen für Ein- und kleinere Mehrfamilienhäuser, um Energie vorzuhalten. ',
    },
    {
      id: 'intelligente-netze',
      title: 'Intelligente Netze',
      text: 'Der Begriff "intelligentes Stromnetz", oder auch häufig "smart grid" (engl.) genannt, ist Teil der Digitalisierung des Stromsystems. Er beschreibt die kommunikative Anbindung der Akteure des Stromsystems von der Erzeugung über den Transport, die Speicherung und die Verteilung bis hin zum Verbrauch an das Stromnetz. Digitale Funktionen im intelligenten Netz sind zum Beispiel wichtig, um Stromverbrauch mit Erzeugungsspitzen aus erneuerbaren Energien zusammenzubringen.',
    },
    {
      id: 'kilowatt-peak',
      title: 'kWp („Kilowatt Peak“)',
      text: 'Ein „Kilowatt Peak“ ist ein Leistungsmaß, das die mögliche Höchstleistung einer Photovoltaikanlage in Kilowatt angibt.',
    },
    {
      id: 'klimaneutralität',
      title: 'Klimaneutralität',
      text: 'Klimaneutralität bedeutet, ein Gleichgewicht zwischen Kohlenstoffemissionen und der Aufnahme von Kohlenstoff aus der Atmosphäre in sogenannten Kohlenstoffsenken herzustellen. Um das zu erreichen, müssen alle Treibhausgasemissionen weltweit durch Kohlenstoffbindung in Senken ausgeglichen werden. Wichtig ist dies insbesondere bei nicht vermeidbaren Emissionen, wie z.B. in der Landwirtschaft oder der Zementindustrie. Natürliche Kohlenstoffsenken sind zum Beispiel Böden, Wälder oder Ozeane. Darüber hinaus gibt es auch künstliche Wege, Kohlenstoff zu binden, beispielsweise in Baumaterialien oder durch das Einlagern von CO\u2082 aus Biomasse oder Luftabscheidung unter der Erde in alte Erdgaslagerstätten (abgekürzt CCS). Laut Schätzungen entfernen natürliche Senken zwischen 9,5 und 11 Gigatonnen CO\u2082 pro Jahr. 2019 betrugen die jährlichen globalen CO\u2082-Emissionen 38 Gigatonnen.',
    },
    {
      id: 'klimaschutzziele',
      title: 'Klimaschutzziele',
      text: 'Klimaschutzziele bezeichnen vor allem Ziele für die Reduktion von Treibhausgasemissionen. Im Juni 2021 hat der Deutsche Bundestag stärkere Klimaschutzziele beschlossen und dafür das neue Klimaschutzgesetz 2021verabschiedet. Darin verankert wird das Ziel, bis 2045 klimaneutral zu sein - also nicht mehr CO\u2082 auszustoßen als wieder über Wälder oder andere Wege absorbiert werden kann. Bis 2030 sollen dafür insgesamt 65 Prozent aller Treibhausgas-Emissionen eingespart werden (im Vergleich zu 1990), das Klimaschutzziel 2040 sind dann 88 Prozent. Übergeordnet gibt es außerdem europäische Klimaziele. Über den Emissionshandel und nationale Emissionsminderungsziele wird dabei ein Ausgleich zwischen den Ländern möglich.',
    },
    {
      id: 'kraft-wärme-kopplung',
      title: 'Kraft-Wärme-Kopplung',
      text: 'Kraft-Wärme-Kopplung ist die gleichzeitige Gewinnung von elektrischer und thermischer Energie, also von Strom und Wärme in einem Kraftwerk. Dies sind insbesondere Erdgas-, Biomasse- und Müllheizkraftwerke und zukünftig auch auf Basis von Wasserstoff.',
    },
    {
      id: 'kilowattstunde',
      title: 'kWh (Kilowattstunde) ',
      text: 'kWh bezeichnet die Maßeinheit Kilowattstunde anhand derer Strom abgerechnet wird. Der aktuelle Strompreis liegt bei circa 32 Cent pro kWh je nach Tarif. Mit einer kWh Strom kann man bspw. 7 Stunden fernsehen oder eine Ladung Wäsche bei 60 Grad waschen. Der Jahres-Richtwert für den Stromverbrauch von einem Ein-Personen-Haushalt liegt bei 1500 kWh, für einen Zwei-Personen-Haushalt bei 2500 kWh. Je nachdem welche elektrischen Geräte genutzt werden und wie sparsam mit Strom umgegangen wird, liegt der tatsächliche Verbrauch höher oder niedriger. ',
    },
    {
      id: 'letztverbraucherregelung',
      title: 'Letztverbraucherregelung',
      text: 'Der Strompreis einschließlich Steuern und Umlagen wird beim sogenannten Letztverbraucher fällig. Letztverbraucher sind also Haushalte oder Stromspeicher. Was als Letztverbraucher gilt ist wichtig, wenn das Energieversorgungssystem flexibler werden, Haushalte bspw. selbst Strom erzeugen oder zwischenspeichern. Zurzeit sind die Regularien noch so, dass hohe Kosten durch Umlagen entstehen, die eine solche Flexibilisierung wirtschaftlich unattraktiv machen. ',
    },
    {
      id: 'mieterstrom',
      title: 'Mieterstrom',
      text: 'Als Mieterstrom wird Strom bezeichnet, der von Solaranlagen auf dem Dach eines Wohngebäudes erzeugt und von dort direkt, das heißt ohne Netzdurchleitung, an Letztverbraucher in diesem Gebäude oder im selben Quartier geliefert und verbraucht wird. In der Praxis erzeugt und liefert der Vermieter den Strom oft nicht selbst, sondern betraut hiermit Dritte, denen er die entsprechenden Dachflächen zur Verfügung stellt. Bei diesen Dritten handelt es sich häufig um auf Energiedienstleistungen spezialisierte Unternehmen.',
    },
    {
      id: 'netzbetreiber',
      title: 'Netzbetreiber',
      text: 'Der Netzbetreiber verantwortet den Stromtransport und ist das erforderliche Bindeglied zwischen Stromerzeugern und Stromverbraucher:innen. In Deutschland existieren vier Netzebenen: Höchstspannungsnetz, Hochspannungsnetz, Mittelspannungsnetz und Niederspannungsnetz. Diese werden von zahlreichen Dienstleistungsunternehmen betrieben, die sich grob in zwei Kategorien einteilen lassen: <strong>Übertragungsnetzbetreiber (ÜNB)</strong>, die überregionale Netze bewirtschaften, und <strong>Verteilnetzbetreiber (VNB)</strong>, die auf regionaler und lokaler Ebene arbeiten.',
    },
    {
      id: 'netzentgelte',
      title: 'Netzentgelte',
      text: 'Das Netzentgelt (auch Netznutzungsentgelt) ist eine Gebühr, die jeder Netznutzer, der Strom oder Gas durch das Versorgungsnetz leitet, an den Netzbetreiber zahlen muss. Haushaltskunden zahlen ihre Netzentgelte nicht direkt, sondern über den Stromanbieter. Mit den Netzentgelten werden Strommasten, Stromleitungen und Umspannwerke durch die Übertrags- und Verteilnetzbetreiber gebaut und gewartet. Wie hoch das Netzentgelt ist, das ein Netzbetreiber erheben darf, wird durch die Bundesnetzagentur festgelegt.',
    },
    {
      id: 'netzausbau',
      title: 'Netzausbau',
      text: 'Der Netzausbau im Stromsystem bezieht sich auf den Ausbau der Leitungen im Stromnetz. Der Ausbau der erneuerbaren Energien stellt neue Anforderungen an die Stromnetze. So wird etwa der erneuerbare Strom aus Windenergie vorrangig im Norden und Osten sowie auf See erzeugt, wo der Wind besonders stark weht. Die größten Stromverbraucher - allen voran große Industriebetriebe - befinden sich aber im Süden und Westen Deutschlands. Der im Norden erzeugte „Windstrom“ muss also dorthin transportiert werden. Mehr Elektrofahrzeuge und Wärmepumpen führen zusätzlich zu einem Ausbaubedarf auf der Ebene des Verteilnetzes, der insgesamt höhere Kosten aufweisen wird als für das Übertragungsnetz.',
    },
    {
      id: 'off-shore-on-shore',
      title: 'Off-Shore/On-Shore',
      text: '„Shore“ ist das englische Wort für Küste. Off-Shore sind also Windkraftanlagen vor der Küste auf dem Meer, On-Shore wiederum alle Windkraftanlagen an Land. ',
    },
    {
      id: 'photovoltaik-anlagen',
      title: 'Photovoltaik-Anlagen (PV-Anlagen)',
      text: 'Photovoltaikanlagen, auch Solaranlagen genannt, produzieren mithilfe von Solarzellen elektrische Energie aus Sonnenlicht. Sie sind meist auf Hausdächern oder auf Freiflächen neben Schienen oder Autobahnen installiert. ',
    },
    {
      id: 'politische-instrumente',
      title: 'Politische Instrumente',
      text: 'Politische Instrumente sind konkrete Maßnahmen, die zum Erreichen eines gewählten Soll-Zustandes beitragen sollen. Dazu zählen marktwirtschaftliche Instrumente, wie Umweltsteuern, Verbote, Standards oder Anreize. ',
    },
    {
      id: 'prosumer',
      title: 'Prosumer',
      text: 'Prosumer ist ein künstlich zusammengesetzter Begriff aus den englischen Begriffen „Producer“ (Hersteller) und „Consumer“ (Verbraucher). Er drückt aus, dass ein Konsument in bestimmten Bereichen auch gleichzeitig ein Produzent sein kann. In der <strong>Energiewende</strong> bezieht der Begriff sich auf Verbraucher:innen, die einerseits Strom beziehen, andererseits selbst produzieren bspw. über die Solaranlage auf dem Dach. ',
    },
    {
      id: 'quartierspeicher',
      title: 'Quartierspeicher',
      text: 'Quartierspeicher sind  <strong>Stromspeicher </strong> in Wohnsiedlungen, die sowohl am Strommarkt eingesetzt werden können, aber  ggf. auch den in der Siedlung selbst erzeugten erneuerbaren Strom gebündelt speichern und für einen lokalen Verbrauch bereitstellen. Im Vergleich zu den kleiner dimensionierten Heimspeichern auf Niederspannungsebene (das heißt im Verteilnetz) sind Quartierspeicher besser geeignet, das Stromnetz auch auf Mittelspannungsebene zu entlasten, das heißt wenn es aufgrund von hohen Lastspitzen neuer Stromverbraucher:innen zu Engpässen kommt. Noch fehlen dazu die rechtlichen Rahmenbedingungen.',
    },
    {
      id: 'raumplanung',
      title: 'Raumplanung',
      text: 'Die Raumplanung ist wichtig für die Ausweisung von Flächen zum Bau von Freiflächensolar- und Windenergieanlagen.  Die Raumplanung legt die Aufteilung und Nutzung eines Raumes, häufig ein Verwaltungsgebiet, fest und wird in Deutschland durch öffentliche Stellen vorgenommen. Dabei werden soziale, ökologische, wirtschaftliche Aspekte berücksichtigt. Der Ausbau von erneuerbaren Energien kann nur gelingen, wenn ausreichend Flächen in der Regionalplanung ausgewiesen werden, also zur Verfügung stehen. Dabei besteht ein Zusammenwirken von Bundes-, Landes- und regionaler Ebene.',
    },
    {
      id: 'regionale-quoten',
      title: 'Regionale Quoten ',
      text: 'Bei Ausschreibungen können durch regionale Quoten bestimmte Standorte oder Technologien beim Ausbau erneuerbarer Energien besonders gefördert werden. So können zum Beispiel regionale Ungleichgewichte beim Zubau von Erzeugung ausgeglichen, oder regional unterschiedlich verbreitete Technologien gefördert werden.',
    },
    {
      id: 'referenzertragsmodell',
      title: 'Referenzertragsmodell',
      text: 'Seit dem <strong>Erneuerbaren Energien Gesetz (EEG)</strong> 2000 sorgt das Referenzertragsmodell dafür, dass die Errichtung und der Betrieb von Windenergieanlagen auch an windschwächeren Standorten wirtschaftlich ist und die Anlagen deshalb nicht nur an besonders windhöffigen Standorten, sondern über das gesamte Bundesgebiet verteilt errichtet werden. Das Referenzertragsmodell führt im Ergebnis dazu, dass Anlagenbetreiber an windschwächeren Standorten eine höhere und an windhöffigeren Standorten eine niedrigere Vergütung je erzeugter <strong>Kilowattstunde</strong> Strom erhalten.',
    },
    {
      id: 'smart-meter',
      title: 'Smart Meter',
      text: 'Ein Smart Meter meint einen digitalen Stromzähler und eine Kommunikationseinheit zur Datenübertragung. Dieses intelligente Messsystem zählt den Stromverbrauch, speichert und verarbeitet die Daten.',
    },
    {
      id: 'solardachpflicht',
      title: 'Solardachpflicht',
      text: 'Die Solardachpflicht (auch solare Baupflicht) bezieht sich auf die Pflicht zur Installation von <strong>Photovoltaikanlagen</strong> auf den Dächern von Neubauten oder auch bei Dachsanierungen. In Baden-Württemberg und Hamburg wurde diese bereits beschlossen und tritt je nach Gebäudeart ab Januar 2022 in Kraft. ',
    },
    {
      id: 'solarthermie',
      title: 'Solarthermie',
      text: 'Solarthermie bezeichnet die Gewinnung von Wärme aus der Sonneneinstrahlung. Besonders gebräuchlich ist sie in der Form der Warmwasserbereitung bspw. mit Sonnenkollektoren auf dem Dach eines Gebäudes. Sie kann aber auch im großen Stil als große Solarfläche angelegt sein und ganze Wohnviertel versorgen.',
    },
    {
      id: 'strommix',
      title: 'Strommix',
      text: 'Der Strommix meint die Zusammensetzung des Stroms nach Energiequellen.',
      image: 'https://strom-report.de/medien/strommix-2020-stromerzeugung-deutschland-1-615x459.jpg'
    },
    {
      id: 'stromnetz',
      title: 'Stromnetz',
      text: 'Das deutsche Stromnetz ist über 1,7 Millionen Kilometer lang und besteht aus einem überregionalen Übertragungsnetz sowie regionalen und lokalen Verteilnetzen. Das Übertragungsnetz lässt sich mit Autobahnen vergleichen: Es verbindet die Regionen in Europa und Deutschland untereinander, nimmt große Mengen elektrischer Energie von großen Erzeugungsanlagen auf und transportiert sie über weite Strecken. Die Strom-Verteilnetze ähneln dem Straßennetz aus Landes- und Kreisstraßen. Sie beziehen die elektrische Energie unter anderem aus dem Übertragungsnetz und verteilen sie im Hochspannungsnetz weiter an Stadtwerke und größere Industrieunternehmen. Um den Strom weiter in die Städte und großen Unternehmen einer Region zu transportieren, wird dann seine Spannung nochmals reduziert und gelangt über das Mittel- und Niederspannungsnetz in die Haushalte.',
    },
    {
      id: 'stromwende',
      title: 'Stromwende',
      text: 'Die Stromwende bezieht sich ausschließlich auf den Umstieg von fossil erzeugten auf erneuerbaren Strom, bildet also einen Teilbereich der <strong>Energiewende</strong> ab. Die Stromproduktion wird dekarbonisiert, sprich eine CO\u2082-freie Produktion des Stroms durch EE-Anlagen. ',
    },
    {
      id: 'stromspeicher',
      title: 'Stromspeicher',
      text: 'Ein <strong>Stromspeicher</strong> bezeichnet Anlagen, die in der Lage sind, Strom unmittelbar von der Erzeugungsanlage oder aus dem Netz aufzunehmen und die gespeicherte Energie zu einem späteren Zeitpunkt wieder abzugeben.',
    },
    {
      id: 'treibhausgase-und-treibhausgaseffekt',
      title: 'Treibhausgase und Treibhausgaseffekt',
      text: 'Treibhausgase (THG) sind verantwortlich für die Erwärmung der Erdatmosphäre, der sog. Treibhausgaseffekt. Vom Menschen verursachte THG-Emissionen verstärken diesen Effekt und führen zur Erwärmung des Klimas. Den größten Anteil an den durch den Menschen verursachten THG-Emissionen hat mit fast 90 % das Kohlendioxid. Ein weiteres schädliches Treibhausgas ist Methan. Es ist auf 100 Jahre bezogen 34-mal so wirksam wie CO\u2082 und entsteht vor allem bei der Tierhaltung, genauer gesagt im Verdauungstrakt von Wiederkäuern. Kurzfristig ist die Klimawirkung noch deutlich höher.',
    },
    {
      id: 'uebertragungsnetz',
      title: 'Übertragungsnetz',
      text: 'Als Übertragungsnetz bezeichnet man den Teil des Stromnetzes, mit dem elektrische Energie über weite Entfernungen geleitet wird. Es besteht aus Höchstspannungsleitungen, also mit besonders hohem Spannungsniveau und zusätzlichen technischen Einrichtungen wie Transformatoren und Leitwarten. Großkraftwerke sind direkt an das Übertragungsnetz angeschlossen, um ihre Energie auf der Höchstspannungsebene einzuspeisen.',
    },
    {
      id: 'verteilungsnetz',
      title: 'Verteilungsnetz',
      text: 'Ein Verteilungsnetz ist ein Netz, das nicht dem Ferntransport, sondern der Feinverteilung von Strom an die einzelnen Verbraucher:innen dient. Das Verteilungsnetz für elektrische Energie arbeitet insbesondere auf der Mittelspannungs- und Niederspannungsebene. Transformatoren übertragen Energie z. B. aus dem Höchstspannungs-Übertragungsnetz oder aus einem Hochspannungsnetz in das Mittelspannungsnetz oder von dort in das Niederspannungsnetz.',
    },
    {
      id: 'zentrale-waermespeicher',
      title: 'Zentrale Wärmespeicher',
      text: 'Wärmespeicher bieten Lösungen, um Energie, die zum Zeitpunkt der Erzeugung nicht direkt verbraucht werden kann, zu einem späteren Zeitpunkt nutzbar zu machen. Wärmespeicher sorgen dafür, dass Wärme, die an warmen Tagen bei der Stromerzeugung in Anlagen mit <strong>Kraft-Wärme-Kopplung (KWK)</strong>, in Industrieprozessen oder <strong>Solarthermieanlagen</strong> bereitgestellt wird, in kälteren Zeiten mit hohem Wärmebedarf noch zur Verfügung steht.',
    },
    {
      id: 'zubau-von-ee-anlagen',
      title: 'Zubau von EE-Anlagen ',
      text: 'Der Zubau von Erneuerbare Energie-Anlagen (EE-Anlagen) beschreibt den Ausbau von EE-Anlagen. Dabei spricht man von „Zubauen", weil so lange „hinzugebaut“ wird, bis die Dekarbonisierung erreicht ist – auf lange Sicht. Auf kurze Sicht wird so lange zugebaut, bis die festgelegten Ausbauziele für ein Jahr erreicht sind. Diese Ziele werden auch als Mengenzubauziele bezeichnet. ',
    },
  ]
}
// <a class="link" data-index="off-shore-on-shore">Offshore-Anlagen</a>
// <a class="link" data-index="netzausbau">Netzausbau</a>
// <a class="link" data-index="erneuerbare-energien-gesetz">EEG</a>
// <a class="link" data-index="eeg-umlage">EEG-Umlage</a>
// <a class="link" data-index="netzentgelte">Netzentgelte</a>
// <a class="link" data-index="netzbetreiber">Netzbetreiber</a>
// <a class="link" data-index="digitalisierung-des-stromnetzes">Digitalisierung des Stromnetzes</a>
// <a class="link" data-index="stromnetz">Stromnetz</a>
// <a class="link" data-index="eigenverbrauchsregelung">Eigenverbrauch</a>
// <a class="link" data-index="mieterstrom">Mieterstrom</a>
// <a class="link" data-index="raumplanung">Raumplanung</a>
// <a class="link" data-index="erneuerbare-energien-strom-quellen">erneuerbaren Energieerzeugung</a>
// <a class="link" data-index="ertragreiche-standorte">ertragreiche Standorte</a>
// <a class="link" data-index="energiewende">Energiewende</a>
// <a class="link" data-index="ausschreibungen">Ausschreibungen</a>
