import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Glossar from '../../../views/Glossar.vue'
import HomeEnergy from '../../../views/HomeEnergy.vue'
import Onboarding from '../../../views/Onboarding.vue'
import Splash from '../../../views/Splash.vue'
import Start from '../../../views/Start.vue'
import AdviceSingle from '../../../views/AdviceSingle.vue'
import AdviceIntro from '../../../views/AdviceIntro.vue'
import AdviceOverview from '../../../views/AdviceOverview.vue'
import ConditionOverview from '../../../views/ConditionOverview.vue'
import ConditionSingle from '../../../views/ConditionSingle.vue'
import End from '../../../views/End.vue'

import dataHome from '../data/home'
import dataSplash from '../data/splash'
import dataStart from '../data/start'
import dataOnboarding from '../data/onboarding'
import dataGlossar from '../data/glossar'
import dataWorld from '../data/world'
import dataScrew from '../data/screw'
import dataScrewOverview from '../data/screw-overview'
import dataScrewIntro from '../data/screw-intro'
import dataConditionOverview from '../data/condition-overview'
import dataConditionSingl from '../data/condition-single'
import dataEnd from '../data/end'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/splash',
    name: 'Splash',
    component: Splash,
    props: {
      data: dataSplash
    }
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
    props: {
      data: dataStart
    }
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    props: {
      data: dataOnboarding
    },
    meta: {
      routeTransition: 'slide-y'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeEnergy,
    props: {
      data: dataHome
    }
  },
  {
    path: '/world',
    name: 'World',
    component: AdviceSingle,
    props: {
      data: dataWorld
    }
  },
  {
    path: '/screw-intro',
    name: 'ScrewIntro',
    component: AdviceIntro,
    props: {
      data: dataScrewIntro
    }
  },
  {
    path: '/screw-overview',
    name: 'ScrewOverview',
    component: AdviceOverview,
    props: {
      data: dataScrewOverview
    }
  },
  {
    path: '/screw',
    name: 'Screw',
    component: AdviceSingle,
    props: {
      data: dataScrew
    }
  },
  {
    path: '/condition',
    name: 'ConditionSingle',
    component: ConditionSingle,
    props: {
      data: dataConditionSingl
    }
  },
  {
    path: '/condition-overview',
    name: 'ConditionOverview',
    component: ConditionOverview,
    props: {
      data: dataConditionOverview
    }
  },
  {
    path: '/glossar',
    name: 'Glossar',
    component: Glossar,
    props: {
      data: dataGlossar
    }
  },
  {
    path: '/end',
    name: 'End',
    component: End,
    props: {
      data: dataEnd
    }
  },
  {
    path: '*',
    component: Splash,
    props: {
      data: dataSplash
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Do not scroll up if there is a glossary overlay
    if (Object.keys(to.query).includes('glossar')){
      return {}
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0}
    }
  }
})

export default router
