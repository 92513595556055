export default {
  items: [
    {
      prevLabel: 'Herausforderungen',
      prevLink: '/condition-overview',
      label: 'Beteiligung',
      headline: 'Beteiligung & Konflikte',
      summary: `
        Beteiligung und Aufklärung können Konflikte entschärfen und Kompromisse finden. Bei den meisten Konflikten zur Umsetzung der Energiewende geht es nicht um das "Ob", sondern um das "Wie". Wichtige Themen dabei sind:
        <ul>
            <li>Umwelt- und Artenschutz</li>
            <li>Zerstörung des Landschaftsbilds durch Wind- oder Solaranlagen</li>
            <li>Aufteilung des Gewinns von neuen Wind- und Solaranlagen</li>
            <li>Gesundheitliche Bedenken (z.B. wegen Schall oder Schattenwurf)</li>
        </ul>
        `,
      items: [
        {
          title: 'Wer darf eigentlich was entscheiden?',
          text: `
                In Deutschland werden Entscheidungen über die Umsetzung der Energiewende auf verschiedenen politischen Ebenen getroffen.
                <ul>
                    <li>Bund und Länder entscheiden über Ziele der Energieversorgung und deren Priorisierung.</li>
                    <li>Auf der regionalen und lokalen Ebene wird dann anschließend über konkrete Flächen und Anlagen entschieden.</li>
                </ul>
                Die Entscheidungen von Bund und Ländern stehen dabei über der <a class="link" data-index="raumplanung">Raumplanung</a>: die dort beschlossenen Ziele bestimmen, welche Flächen die Kommunen zur Verfügung stellen müssen. Bürger:innen können sich jedoch erst  auf lokaler Ebene bei der Raumplanung beteiligen und mitsprechen. Auf Landes- und Bundesebene ist eine solche Beteiligung nicht vorgesehen. Das führt dazu, dass Bürger:innen oft erst bei der konkreten Umsetzung vor Ort miteinbezogen werden, aber womöglich schon bei den Zielen und Prioritäten gern hätten mitentscheiden wollen. An welcher Stelle würden Sie gerne mitsprechen wollen?
            `,
        },
      ],
      image: 'condition-discuss',
    },
    {
      label: 'Verteilungsgerechtigkeit',
      headline: 'Verteilungsgerechtigkeit',
      summary: `Viele Bürger:innen wünschen sich, dass es gerecht zugeht bei den anstehenden Veränderungen. Hier betrachten wir die Verteilung finanzieller Lasten und Gewinne, aber auch die Aspekte, die die räumliche Verteilung des Ausbaus Erneuerbarer betrifft.`,
      items: [
        {
          title: 'Der Blick auf die Landkarte',
          text: `
                Zurzeit ist der Zubau <a class="link" data-index="erneuerbare-energien-strom-quellen">erneuerbarer Energie</a> in Deutschland nicht gleichmäßig verteilt. Im Norden und im Osten Deutschlands wurden viele Windkraftanlagen gebaut, wohingegen im Süden mehr solare Anlagen zugebaut wurden. Das macht manche stolz, andere aber auch frustriert.
                <br><br>
                Die dezentrale Welt ermöglicht einen gleichmäßiger verteilten Zubau von Wind- und Solaranlagen unter Beteiligung der lokalen Bevölkerung. Die Erzeugung findet eher dort statt, wo auch Strom verbraucht wird. Die zentrale Welt setzt auf ertragreiche Standorte und geringere Beteiligung, da die Umsetzung von großen Energieversorgern übernommen wird. Die <a class="link" data-index="ertragreiche-standorte">ertragreichen Orte</a> tragen dann die Hauptlast der Versorgung durch erneuerbare Energie. Was empfinden Sie als gerecht?
            `,
        },
        {
          title: 'Der Blick in den privaten Geldbeutel. ',
          text: 'Steuern, wie zum Beispiel die Energiesteuer, sind eine wichtige Einnahmequelle der öffentlichen Haushalte. Auch auf erneuerbar erzeugten Strom wird eine Stromsteuer gezahlt. Im Jahr 2021 wurde in Deutschland zudem ein <a class="link" data-index="co2-preis">CO\u2082-Preis</a> pro ausgestoßener Tonne CO\u2082 im Verkehr und Wärmebereich in eingeführt, um klimaschädliches Verhalten und Technologien zu verteuern. Damit stehen dem Staat weitere Einnahmen zur Verfügung, die zum Beispiel genutzt werden können, um eine Pro-Kopf-Rückvergütung auszuschütten und Bürger:innen für Mehrkosten im Klimaschutz zu entlasten. Die, die sich sowieso schon klimafreundlich verhalten, also beispielsweise kein Auto fahren und Strom sparen,  profitieren also besonders. Finden Sie eine Pro-Kopf-Rückverteilung gerecht oder sollen andere Steuern dafür gesenkt werden?',
        },
        {
          title: 'Verpflichtende Geschäftsanteile an Windkraft- und Solaranlagen',
          text: `
                Um sicherzugehen, dass Menschen vor Ort in den Aufbau von Erzeugungsanlagen einbezogen werden, haben manche Bundesländer beschlossen, dass eine gewisse Prozentzahl der Geschäftsanteile durch Bürger:innen vor Ort gehalten werden muss, zum Beispiel im Umkreis von 5 Kilometern. Der Vorteil: Anwohner:innen müssen direkt beteiligt werden und können vom Anlagenbau finanziell profitieren. Der Nachteil: Nicht immer lässt sich die Gruppe der Betroffenen räumlich gut fassen und nicht allen dort ist es möglich, sich finanziell zu beteiligen. Außerdem ist die finanzielle Beteiligung manchmal mit besonderen finanziellen Risiken verbunden. Wenn es um lokale Beteiligung geht, wen halten Sie für betroffen? Und wie könnte man Fairness gewährleisten?
            `,
        },
        {
          title: 'Verteilung kommunaler Gewinne',
          text: `
                Unabhängig vom Besitz der Anlage kann eine Anforderung sein, dass ein Teil der Gewinne an die Kommune(n) der Erzeugungsstandorte fließt. Das machen manche Projektentwickler übrigens derzeit schon freiwillig.
                </br></br>
                Die Gewinne können vergemeinschaftet werden und fließen in den kommunalen Haushalt. Der Vorteil: Die Gelder können für andere kommunale Projekte ausgegeben werden, wie Parks, Kitas oder Spielplätze. Die Gewinnbeteiligung ist nicht von der individuellen Möglichkeit, zu investieren, abhängig. Der Nachteil: Wenn das Geld in den kommunalen Haushalts fließt, kann es nicht zweckgebunden werden, daher es ist manchmal schwer nachzuvollziehen, wo das Geld investiert wird.
                </br></br>
                Alternativ können kommunale Gewinne auch durch eine direkte finanzielle Ausschüttung an alle Mitglieder der Gemeinde verteilt werden. Der Vorteil: Alle Menschen in der Gemeinde haben unmittelbar Anteil an finanziellen Gewinnen. Der Nachteil: Gewinne stehen nicht für Investitionen zur Verfügung, die allen zugutekämen.
                </br></br>
                Wie sollten Kommunen für mehr Gerechtigkeit sorgen?
            `,
        },
        {
          title: 'Genossenschaftliche Vergemeinschaftung',
          text: `
                Eine <a class="link" data-index="energie-genossenschaft">Genossenschaft</a> ist eine Möglichkeit, Gewinne innerhalb einer Gruppe zu vergemeinschaften, die Investitionen aber an einen gemeinsamen Zweck zu binden – zum Beispiel an die Beschleunigung der Energiewende vor Ort. Der Vorteil: Anders als im öffentlichen Haushalt kann das Geld so investiert werden, wie die Mitglieder es für richtig halten. Der Nachteil: Meistens sind Investitionen an die Mitglieder der Genossenschaft gebunden, sodass unter Umständen nicht alle Mitglieder der Kommune oder lokalen Gemeinschaft teilhaben können. Denken Sie, dass Genossenschaften eine hilfreiche Beteiligungsmöglichkeit darstellen?
            `,
        },
      ],
      image: 'condition-scale',
    },
    {
      nextLabel: 'Abschließen',
      nextLink: '/end',
      label: 'Zugangsmöglichkeiten',
      headline: 'Zugangsmöglichkeiten',
      summary: 'Der Weg durch beide Welten zeigt: Viele müssen  auf unterschiedlichen Ebenen immer wieder Verantwortung übernehmen, um die <a class="link" data-index="energiewende">Energiewende</a> mitzugestalten. Und viele möchten Verantwortung übernehmen, noch fehlen ihnen aber die  Möglichkeiten.  ',
      items: [
        {
          title: 'Im Eigenheim Verantwortung übernehmen',
          text: 'Wer sein eigenes Haus fit für die <a class="link" data-index="energiewende">Energiewende</a> machen will, kann z.B. durch Energieberatung Zugang zu Wissen und finanzieller Förderung erhalten. Diese Energieberatung muss allerdings von der dena (Deutsche Energie-Agentur) zertifiziert sein, nur dann können Fördergelder beantragt werden. Gleichzeitig haben <a class="link" data-index="energieberater-in">Energieberater:innen</a> aber noch kein festgelegtes Berufsbild. Und nicht immer stehen ausreichend <a class="link" data-index="energieberater-in">Energieberater:innen</a> zur Verfügung. Wichtig ist es deshalb, den Zugang zu Energieberatung und Fördergeldern zu vereinfachen. Wie können Eigenheim-Besitzer ihrer Meinung nach unterstützt werden, um zum Klimaschutz zu beizutragen?',
        },
        {
          title: 'Gesicherte Zugangsmöglichkeiten durch bessere Ausschreibungsbedingungen',
          text: 'Um die Akteursvielfalt bei der erneuerbaren Energieerzeugung zu erhalten, wurde bei Ausschreibungen nach dem <a class="link" data-index="erneuerbare-energien-gesetz">Erneuerbare-Energien-Gesetz (EEG)</a> ein fester Anteil eingeführt, der an Bürgerenergieprojekte gehen sollte. Der Vorteil: Die umfangreichen Anforderungen des <a class="link" data-index="ausschreibungen">Ausschreibungssystems</a>, die für Bürgerprojekte kaum zu erfüllen sind, wurden gelockert, um die Teilhabe zu erhöhen. Der Nachteil: In der Praxis tarnten sich große Projektentwickler:innen als Bürgerprojekte und erhielten so oft alle Zuschläge dieser Kategorie, die eigentlich für andere Akteure bestimmt war. Durch bessere Regelung kann das verhindert werden. Sollte weiterhin ein fester Anteil für Bürgerenergieprojekte bestehen?',
        },
        {
          title: 'Klimafreundlich Geld anlegen',
          text: 'Bisher fehlen einheitliche Regeln und transparente Prozesse für Finanzprodukte zur Investition in die <a class="link" data-index="energiewende">Energiewende</a>. Wer sein Geld zur Bank bringt, hat es schwer zu verstehen, ob es hier in klimafreundliche oder klimaschädliche Produkte investiert wird. Spezifische Produkte zur Investition in die Energiewende gibt es wenig. Finanzprodukte mit klaren Standards sowie eine entsprechende Beratungspflicht könnten das ändern. Auch institutionelle Anleger, wie Rentenkassen oder Versicherer, könnten verpflichtet werden, ihr Geld in Energiewende-Produkte zu investieren. Was erwarten Sie von den Banken und vom Staat im Bereich der klimafreundlichen Finanzierung und Geldanlage?',
        },
      ],
      image: 'condition-grow',
    },
  ]
}
