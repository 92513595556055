
































import { STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST } from '@/fixtures/storage'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { vwTOpx } from '../utils/convertor'

@Component({
})
export default class ConditionSingle extends Vue {
  @Prop() data!: {
    items: {
      headline: string
      label: string
      prevLabel: string
      nextLabel: string
      nextLink?: string
      prevLink?: string
      image: string
      summary?: string
      items: {
        title: string
        text: string
      }[]
    }[]
  }

  currentSlideIndex = 0
  itemsCheckedMap: { [url: string]: boolean } = {}


  get currentItem() {
    return this.data.items[this.currentSlideIndex]
  }

  get prevText() {
    return this.currentItem?.prevLabel || this.data.items[this.currentSlideIndex - 1]?.label
  }

  get nextText() {
    return this.currentItem?.nextLabel || this.data.items[this.currentSlideIndex + 1]?.label
  }

  $refs!: {
    itemsOuter: HTMLElement
  }

  @Watch('$route.query.index')
  watchRoute(indexQuery: string | undefined) {
    if (indexQuery === undefined) {
      return
    }

    const index = parseInt(indexQuery.toString())

    if (index >= 0) {
      this.currentSlideIndex = index

      this.handleStorage()
    }
  }

  mounted() {
    const index = parseInt(this.$route.query.index?.toString())

    if (index >= 0) {
      this.currentSlideIndex = index

      this.handleStorage()
    }
  }

  handleStorage() {
    const data = localStorage.getItem(STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST)

    if (data) {
      this.itemsCheckedMap = JSON.parse(data)
    }

    localStorage.setItem(STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST, JSON.stringify({
      ...this.itemsCheckedMap,
      [this.$route.fullPath]: true
    }))
  }

  @Watch('currentSlideIndex')
  onSlide(currentSlideIndex: number) {
    this.$refs.itemsOuter.scrollLeft = vwTOpx((currentSlideIndex) * 100)
  }

  onNext() {
    const nextLink = this.data.items[this.currentSlideIndex]?.nextLink

    if (nextLink) {
      this.$router.push(nextLink)
      return
    }

    this.currentSlideIndex += 1
  }

  onPrev() {
    const prevLink = this.data.items[this.currentSlideIndex]?.prevLink

    if (prevLink) {
      this.$router.push(prevLink)
      return
    }

    if (this.currentSlideIndex === 0) return

    this.currentSlideIndex -= 1
  }
}
