











import { STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST } from '@/fixtures/storage'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})
export default class ConditionOverview extends Vue {
  @Prop() data!: {
    overline: string
    headline: string
    text: string
    items: {
      headline: string
      subline: string
      link: string
    }[]
  }

  mounted() {
    const data = localStorage.getItem(STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST)

    if (data) {
      this.itemsCheckedMap = JSON.parse(data)
    }
  }

  itemsCheckedMap: { [url: string]: boolean } = {}

  get listItems() {
    return this.data.items.map((item) => ({
      headline: item.headline,
      subline: item.subline,
      isChecked: this.itemsCheckedMap[item.link]
    }))
  }

  onItem(index: number) {
    const item = this.data.items[index]

    if (item) {
      this.$router.push(item.link)

      this.$set(this.itemsCheckedMap, item.link, true)
      localStorage.setItem(STORAGE_KEY_ENERGY_CONDITIONS_OVERVIEW_LIST, JSON.stringify(this.itemsCheckedMap))
    }
  }
}
