export default {
  overline: 'Übersicht',
  headline: 'Herausforderungen',
  text: 'Vom Umbau des Energieversorgungssystem ist die gesamte Gesellschaft betroffen. <br /> <br /> An welchen Stellschrauben können wir drehen um <br /> <br /> 1) Konflikten konstruktiv zu begegnen <br /> 2) mehr Gerechtigkeit zu erreichen und <br /> 3) Teilhabe für Bürger:innen zu erhöhen? <br /> <br /> Diese Punkte wurden bei den Fokusgruppen als wichtige Aspekte von den Bürger:innen benannt. Die Stellschrauben, die diese Aspekte adressieren, sind in beiden Energiewelten denkbar. Wie denken Sie darüber?',
  items: [
    {
      headline: 'Beteiligung & Konflikte',
      subline: '',
      link: '/condition?index=0',
    },
    {
      headline: 'Verteilungsgerechtigkeit',
      subline: '',
      link: '/condition?index=1',
    },
    {
      headline: 'Zugangsmöglichkeiten',
      subline: '',
      link: '/condition?index=2',
    },
  ]
}