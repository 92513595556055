export default {
  tabsLabel: 'Strombedarf in Deutschland',
  dataLabel: 'Unit',
  headline: 'Die Herausforderung Wie können wir das schaffen?',
  text: 'Ein oder zwei Sätze',
  button: 'Zu den einzelnen Stellschrauben',
  buttonLink: 'screw-overview',
  items: [
    {
      label: 'Jetzt',
      data: 12345678
    },
    {
      label: '2030',
      data: 999812
    },
    {
      label: '2045',
      data: 12392
    },
  ]
}