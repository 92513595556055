export default {
  labelCentral: 'Zentral',
  labelDecentral: 'Dezentral',
  overlayHeadline: 'Info',
  overlayButtonClose: 'Ok',
  items: [
    {
      prevLabel: 'Stellschrauben',
      prevLink: 'screw-overview',
      label: 'Technologiemix',
      headline: 'Technologiemix anpassen',
      central: {
        text: 'Alle <a class="link" data-index="ertragreiche-standorte">ertragreichen Standorte</a> müssen für den Aufbau von Windrädern genutzt werden. Bestehende Windräder müssen, wo möglich, durch leistungsfähigere ausgetauscht werden. Zusätzlich müssen auf weiteren Flächen Windräder ausgebaut werden. Auch hier muss die verfügbare Fläche für erneuerbare Energieerzeugung erhöht werden. Allgemein müssen schneller als bisher vorgesehen Windkraftanlagen aufgebaut werden. Dazu ist es notwendig, das derzeitige <a class="link" data-index="ausschreibungen">Ausschreibungssystem</a>  zu verändern, zum Beispiel mit Blick auf die jährlich vorgesehenen <a class="link" data-index="zubau-von-ee-anlagen">Zubaumengen</a>. <a class="link" data-index="abstandsregelung">Abstandsregeln</a> ohne unmittelbare gesundheitliche oder naturschutzrechtliche Bezüge müssen wir aufgeben. Genehmigungsprozesse müssen vereinheitlicht, beschleunigt und digitalisiert werden – insbesondere für den Ersatz bestehender Windanlagen durch neue, größere Anlagen (sogenanntes Repowering). ',
        image: 'screw-technology-central',
      },
      decentral: {
        text: 'In einer dezentralen Welt muss Solarstrom viel stärker ausgebaut werden. Fast alle Dächer sind mit  Solarmodulen bestückt. Das wird durch eine <a class="link" data-index="solardachpflicht">Solardachpflicht</a> erreicht und kann durch Förderungen  wie zinsfreie Darlehen unterstützt werden. Aber auch entlang der Autobahnen und auf Freiflächen in  der Landwirtschaft werden Solaranlagen installiert. Weil Sonne allein nicht ausreicht, um unseren  Strombedarf zu decken, müssen außerdem Windkraftanlangen zugebaut werden. Dazu müssen auch die <a class="link" data-index="abstandsregelung">Abstandsregeln</a> für Windräder gelockert werden, um mehr Flächen dafür zur Verfügung zu  haben. Insgesamt muss die verfügbare Fläche für <a class="link" data-index="erneuerbare-energien-strom-quellen">erneuerbaren Energien</a> daher erhöht werden. Es ist  aber nicht notwendigerweise so, dass Waldgebiete in der dezentralen Welt von Windparks genutzt werden müssen. ',
        image: 'screw-technology-decentral',
      },
    },
    {
      label: 'Verteilung',
      headline: 'Geografische Verteilung der Erzeugung',
      central: {
        text: 'Wenn erneuerbarer Strom vor allem in großen Windparks mit vielen Anlagen erzeugt werden soll, müssen alle <a class="link" data-index="ertragreiche-standorte">ertragreichen Standorte</a> ohne Ausnahme genutzt werden. Zusätzlich müssen weitere Windparks an weniger <a class="link" data-index="ertragreiche-standorte">ertragreichen Standorten</a> aufgebaut werden. Da diese aber nicht so rentabel sind, könnten Windprojekte beispielsweise mit finanziellen Ausgleichen gefördert werden.  ',
        image: 'screw-divide-central',
      },
      decentral: {
        text: 'Wenn erneuerbarer Strom vor allem verteilt und mit hoher Beteiligung erzeugt werden soll, müssen wir in ganz Deutschland möglichst viele Solaranlagen aufbauen. Alle Regionen müssen dazu beitragen. Insbesondere Dachsolaranlagen nehmen stark zu.',
        image: 'screw-divide-decentral',
      },
    },
    {
      label: 'Strompreis',
      headline: 'Strompreis',
      overlay: {
        subline: '',
        image: 'screw-bar-circle'
      },
      central: {
        text: 'Der durchschnittliche Strompreis wird sinken, weil erneuerbarer Strom generell günstiger ist. Die dabei etwas teureren Dachsolaranlagen werden nicht so intensiv eingesetzt. In der zentralen Welt fallen erhöhte Kosten für den <a class="link" data-index="netzausbau">Netzausbau</a> an. Insgesamt muss Strom günstiger werden, damit der Umstieg weg von fossilen Brennstoffen auf Strom attraktiv ist. Das wird vor allem über die Umgestaltung von Steuern und Abgaben erreicht. Die <a class="link" data-index="eeg-umlage">EEG-Umlage</a> auf den Eigenverbrauch müsste dafür beispielsweise entfallen. ',
        image: 'screw-bar-central',
      },
      decentral: {
        text: 'Der durchschnittliche Strompreis wird sinken, weil erneuerbarer Strom günstiger ist. In der dezentralen Welt fallen höhere Kosten für die Digitalisierung und Speicherung an, aber die Kosten für den <a class="link" data-index="netzausbau">Netzausbau</a> sind geringer. Dadurch bleiben die Netzentgelte gleich. Insgesamt muss Strom günstiger werden, damit der Umstieg von fossilen Brennstoffen auf Strom attraktiv ist. Das wird vor allem über die Umgestaltung von Steuern und Abgaben erreicht. Die <a class="link" data-index="eeg-umlage">EEG-Umlage</a> auf den Eigenverbrauch müsste dafür  beispielsweise entfallen. ',
        image: 'screw-bar-decentral',
      },
    },
    {
      nextLabel: 'Herausforderungen',
      nextLink: '/condition-overview',
      label: 'Netzausbau',
      headline: 'Netzausbau',
      central: {
        text: '<a class="link" data-index="netzbetreiber">Netzbetreiber</a> müssen verstärkt in den Ausbau von Leitungen investieren. Bisher dürfen Netzbetreiber die Investitionen in Leitungen für die Berechnung ihrer Einnahmen zugrunde legen. Dieses System kann daher bestehen bleiben. <a class="link" data-index="netzentgelte">Netzentgelte</a> für Stromverbraucher:innen würden  dadurch steigen. <br /><br />Ob sich in einem zentralisierten Netz technische Störungen oder gezielte Angriffe von außen  schneller großflächig auswirken, kann wissenschaftlich zurzeit nicht eindeutig beantwortet werden. ',
        image: 'screw-net-central',
      },
      decentral: {
        text: '<a class="link" data-index="netzbetreiber">Netzbetreiber</a> erhalten derzeit vornehmlich Anreize für Investitionen in Leitungen. Für eine dezentrale Energiewelt müssen wir jedoch mehr in die <a class="link" data-index="digitalisierung-des-stromnetzes">Digitalisierung des Stromnetzes</a> investieren, zum Beispiel in digitale Stromzähler. Digitale Stromzähler sind wichtig, um Stromverbrauch zu unterbrechen oder zu steuern, so wie es das Stromangebot im <a class="link" data-index="stromnetz">Stromnetz</a> aktuell erfordert. Ob ein dezentrales System weniger anfällig für großflächige Auswirkungen von technischen Störungen oder Angriffen von außen ist, kann wissenschaftlich zurzeit nicht eindeutig beantwortet werden.',
        image: 'screw-net-decentral',
      },
    },
  ]
}
