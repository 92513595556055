









































































































































import { STORAGE_KEY_ENERGY_SCREW_OVERVIEW_LIST } from '@/fixtures/storage'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { vwTOpx } from '../utils/convertor'

@Component({
})
export default class AdviceSingle extends Vue {
  @Prop() data!: {
    labelCentral: string
    labelDecentral: string
    overlayHeadline: string
    overlayButtonClose: string
    items: {
      headline: string
      label: string
      nextLabel?: string
      nextLink?: string
      prevLabel?: string
      prevLink?: string
      overlay?: {
        subline: string
        text?: string
        image?: string
      }
      central: {
        text: string
        image: string
        dataVisual: {
          type: string
          maxData: number
          items: {
            label: string
            items: {
              data?: number
              label: string
              color: string
              items: { data: number, label: string, color?: string }[]
            }[]
          }[]
        }
      }
      decentral: {
        text: string
        image: string
        dataVisual: {
          type: string
          maxData: number
          items: {
            label: string
            items: {
              data?: number
              label: string
              color: string
              items: { data: number, label: string, color?: string }[]
            }[]
          }[]
        }
      }
    }[]
  }

  currentSlideIndex = 0
  isOverlayOpen = false
  isOverlayMapOpen = false
  itemsCheckedMap: { [url: string]: boolean } = {}
  currentMap: {
    label: string
    color: string
    items: { data: number, label: string }[]
  } | null = null

  get currentItem() {
    return this.data.items[this.currentSlideIndex]
  }

  get prevText() {
    return this.currentItem?.prevLabel || this.data.items[this.currentSlideIndex - 1]?.label
  }

  get nextText() {
    return this.currentItem?.nextLabel || this.data.items[this.currentSlideIndex + 1]?.label
  }

  $refs!: {
    itemsOuter: HTMLElement
    text: HTMLElement
  }

  @Watch('$route.query.index')
  watchRoute(indexQuery: string | undefined) {
    if (indexQuery === undefined) {
      return
    }

    const index = parseInt(indexQuery.toString())

    if (index >= 0) {
      this.currentSlideIndex = index

      this.handleStorage()
    }
  }

  @Watch('isOverlayOpen')
  watchOverlay(isOpen: boolean) {
    setTimeout(() => {

      if (isOpen === false || !this.$refs.text) return

      this.handleGlossarLinks()
    })
  }

  mounted() {
    const index = parseInt(this.$route.query.index?.toString())

    if (index >= 0) {
      this.currentSlideIndex = index

      this.handleStorage()
    }

    window.addEventListener('resize', this.onResize)

  }

  handleGlossarLinks() {
    const $glossarLinks = this.$refs.text.querySelectorAll('.link')

    $glossarLinks.forEach(($link) => {
      $link.addEventListener('click', () => {
        if ($link instanceof HTMLElement) {
          this.$router.push({ query: { glossar: $link.dataset.index || '' } })
        }
      })
    })
  }

  handleStorage() {
    const data = localStorage.getItem(STORAGE_KEY_ENERGY_SCREW_OVERVIEW_LIST)

    if (data) {
      this.itemsCheckedMap = JSON.parse(data)
    }

    localStorage.setItem(STORAGE_KEY_ENERGY_SCREW_OVERVIEW_LIST, JSON.stringify({
      ...this.itemsCheckedMap,
      [this.$route.fullPath]: true
    }))
  }

  @Watch('currentSlideIndex')
  onSlide(currentSlideIndex: number) {
    this.$refs.itemsOuter.scrollLeft = this.$refs.itemsOuter.clientWidth*currentSlideIndex
  }

  onResize() {
    this.$refs.itemsOuter.scrollLeft = this.$refs.itemsOuter.clientWidth*this.currentSlideIndex
  }

  onNext() {
    const nextLink = this.data.items[this.currentSlideIndex]?.nextLink

    if (nextLink) {
      this.$router.push(nextLink)
      return
    }

    this.currentSlideIndex += 1
  }

  onPrev() {
    const prevLink = this.data.items[this.currentSlideIndex]?.prevLink

    if (prevLink) {
      this.$router.push(prevLink)
      return
    }

    if (this.currentSlideIndex === 0) return

    this.currentSlideIndex -= 1
  }

  onInfo() {
    this.isOverlayOpen = true
  }

  onMap(item: {
    label: string
    color: string
    items: { data: number, label: string }[],
  }) {
    this.currentMap = item
    this.isOverlayMapOpen = true
  }

  getBatteriesMaxDataItems(index: number): number {
    const dataItems: number[] = []
    const items = [
      ...this.data.items[index].central.dataVisual.items,
      ...this.data.items[index].decentral.dataVisual.items
    ]

    items.forEach((item) => {
      item.items.forEach((child) => {
        dataItems.push(child.data || 0)
      })
    })

    return Math.max.apply(Math, dataItems)
  }
}
