export default {
  labelCentral: 'Zentral',
  labelDecentral: 'Dezentral',
  overlayHeadline: 'Info',
  overlayButtonClose: 'Ok',
  items: [
    {
      label: 'Zentral und dezentral',
      headline: 'Zentrale und dezentrale Welten',
      central: {
        image: 'central',
        text: '<a class="link" data-index="große-energieerzeuger">Große Energieerzeuger</a> gewährleisten zentral unsere Stromversorgung. Das ist kostengünstig, da vor allem Energieanlagen dort entstehen, wo es am sinnvollsten ist, z.B. wo der Wind am meisten weht. Große Windparks versorgen uns mit Windstrom. Solarstrom spielt eine kleinere Rolle. Um den Strom zu den Verbrauchern zu bringen, brauchen wir leistungsstärkere <a class="link" data-index="uebertragungsnetz">Übertragungsnetze</a> und große Energiespeicher.  ',
      },
      decentral: {
        image: 'imageDecentral',
        text: 'Die Stromversorgung erfolgt dezentral an vielen verschiedenen Orten. Sie rückt näher an den Alltag vieler Menschen heran und setzt auf hohe gesellschaftliche Beteiligung. Auch viele Menschen haben privat oder z.B. als Genossenschaften kleine Erzeugungsanlagen. Das führt zu einer Welt mit mehr Solarstrom und einem etwas geringeren Anteil von Windkraft als in der zentralen Welt. Wir brauchen dafür intelligentere <a class="link" data-index="stromnetz">Stromnetze</a>, flexible Stromverbraucher und verteilte Speicher.',
      },
    },
    {
      label: 'Stromerzeugung',
      headline: 'Wie wird der Strom erzeugt?',
      overlay: {
        subline: 'Wie wird der Strom erzeugt?',
        text: 'Dadurch, dass  Strom ins Zentrum der Energieversorgung gesetzt wird, muss deutlich mehr Strom produziert werden. Wind- und Solarstrom spielen in beiden Welten eine Rolle, da sie sich über die Jahreszeiten so gut ergänzen. Aber jeweils eine Form der Stromproduktion dominiert in den jeweiligen Welten: Solarstrom in der dezentralen und Windstrom in der zentralen Welt.',
      },
      central: {
        text: 'Strom wird verstärkt durch große Windenergieanlagen erzeugt: vor der Küste <a class="link" data-index="off-shore-on-shore">(offshore)</a> und an Land  <a class="link" data-index="off-shore-on-shore">(onshore)</a>, vorwiegend an Standorten mit viel Wind. Solche Anlagen stehen auch in Wäldern. Es gibt  auch einige Solaranlagen auf Dachflächen und Freiflächen. Die Gesamtkosten sind niedriger,  allerdings werden sie nicht gleichmäßig auf alle in der Gesellschaft verteilt. Auch die Gewinne  konzentrieren sich auf einige wenige Unternehmen.         ',
        dataVisual: {
          type: 'bar-vertical',
          label: 'TWh',
          items: [
            {
              label: '2020',
              items: [
                { label: 'Sonst', data: 128.8, },
                { label: 'Kohlekraftwerke', data: 117.53, },
                { label: 'Gaskraftwerke', data: 59.1, },
                { label: 'Wind-Onshore', data: 104.5852941, },
                { label: 'Wind-Offshore', data: 27.11470588, },
                { label: 'Solar (Photovoltaik)', data: 50.7, },
              ]
            },
            {
              label: '2030',
              items: [
                { label: 'Sonst', data: 117.9049758 },
                { label: 'Kohlekraftwerke', data: 0 },
                { label: 'Gaskraftwerke', data: 104.4599196 },
                { label: 'Wind-Onshore', data: 279.9885308 },
                { label: 'Wind-Offshore', data: 78.15663992 },
                { label: 'Solar (Photovoltaik)', data: 95.80381529 },
              ]
            },
            {
              label: '2045',
              items: [
                { label: 'Sonst', data: 92.47365368 },
                { label: 'Kohlekraftwerke', data: 0 },
                { label: 'Gaskraftwerke', data: 73.72091359 },
                { label: 'Wind-Onshore', data: 541.518098 },
                { label: 'Wind-Offshore', data: 73.18733576 },
                { label: 'Solar (Photovoltaik)', data: 194.6100907 },
              ]
            },
          ]
        },
      },
      decentral: {
        text: ' Strom kommt verstärkt aus Solaranlagen: Auf fast allen Dachflächen von privaten und öffentlichen  Gebäuden sind Solarmodule. Zusätzlich stehen Solaranlagen auf Äckern, neben Autobahnen und  weiteren Freiflächen. Windkraft wird an ertragreichen <a class="link" data-index="ertragreiche-standorte">Standorten</a> ausgebaut. Die Gesamtkosten  sind höher, da Solardachanlagen teurer für das Energieversorgungssystem sind als Windkraftanlagen ',
        dataVisual: {
          type: 'bar-vertical',
          label: 'TWh',
          items: [
            {
              label: '2020',
              items: [
                { label: 'Sonst', data: 128.8 },
                { label: 'Kohlekraftwerke', data: 117.53 },
                { label: 'Gaskraftwerke', data: 59.1 },
                { label: 'Wind-Onshore', data: 104.5852941 },
                { label: 'Wind-Offshore', data: 27.11470588 },
                { label: 'Solar (Photovoltaik)', data: 50.7 },
              ]
            },
            {
              label: '2030',
              items: [
                { label: 'Sonst', data: 117.9027046 },
                { label: 'Kohlekraftwerke', data: 0 },
                { label: 'Gaskraftwerke', data: 78.95040956 },
                { label: 'Wind-Onshore', data: 235.2867221 },
                { label: 'Wind-Offshore', data: 78.15607565 },
                { label: 'Solar (Photovoltaik)', data: 177.9323718 },
              ]
            },
            {
              label: '2045',
              items: [
                { label: 'Sonst', data: 92.46882137 },
                { label: 'Kohlekraftwerke', data: 0 },
                { label: 'Gaskraftwerke', data: 60.90246723 },
                { label: 'Wind-Onshore', data: 403.4288397 },
                { label: 'Wind-Offshore', data: 73.18698214 },
                { label: 'Solar (Photovoltaik)', data: 378.3391356 },
              ]
            },
          ]
        },
      },
    },
    {
      label: 'Dachsolar',
      headline: 'Detailbetrachtung: Wo gibt es Dachsolaranlagen?',
      overlay: {
        subline: 'Detailbetrachtung: Wo gibt es Dachsolaranlagen?',
        text: 'Solaranlagen auf Dächern von Privathäusern aber auch Unternehmen können verwendet werden, um mehr Strom vor Ort zu produzieren. Wird dies im großen Stil betrieben, erhöht sich die Beteiligung der Bevölkerung an der Energiewende enorm. Außerdem sind diese Flächen sozusagen ‚frei‘. Belegt man sie mit Solaranlagen müssen z.B. keine landwirtschaftlichen Flächen genutzt werden. Im gleichen Zug ist es aber wichtig, dass die Bevölkerung sich dann auch als sogenannte ‚Prosumer‘ versteht – sie produzieren und konsumieren nicht nur ihren eigenen Strom, sondern sind auch bereit ihn in das Stromnetz für andere einzuspeisen. ',
      },
      central: {
        text: 'Da die Versorgung überwiegend durch Windkraftanlagen gewährleistet wird, ist die Beteiligung an der Stromproduktion durch Bürgerinnen und Bürger über Dachsolaranlagen eher gering. Trotzdem sind auf Dachflächen von privaten und öffentlichen Gebäuden Solarmodule installiert. ',
        dataVisual: {
          type: 'map',
          maxData: 1000,
          items: [
            {
              label: '2030',
              items: [
                {
                  label: 'Solar',
                  color: '#893E63',
                  items: [
                    { data: 227.9381344, label: 'Baden-Württemberg' },
                    { data: 188.2047688, label: 'Bayern' },
                    { data: 912.8306693, label: 'Berlin' },
                    { data: 74.2524244, label: 'Brandenburg' },
                    { data: 704.7535699, label: 'Bremen' },
                    { data: 739.3937896, label: 'Hamburg' },
                    { data: 185.7355289, label: 'Hessen' },
                    { data: 55.02268164, label: 'Mecklenburg-Vorpommern' },
                    { data: 154.2421262, label: 'Niedersachsen' },
                    { data: 320.0456894, label: 'Nordrhein-Westfalen' },
                    { data: 159.4161983, label: 'Rheinland-Pfalz' },
                    { data: 272.4293713, label: 'Saarland' },
                    { data: 127.6473998, label: 'Sachsen' },
                    { data: 95.49834149, label: 'Sachsen-Anhalt' },
                    { data: 145.859956, label: 'Schleswig-Holstein' },
                    { data: 102.8794112, label: 'Thüringen' },
                  ]
                },
              ]
            },
            {
              label: '2045',
              items: [
                {
                  label: 'Solar',
                  color: '#893E63',
                  items: [
                    { data: 381.1546575, label: 'Baden-Württemberg' },
                    { data: 296.3923736, label: 'Bayern' },
                    { data: 2110.740384, label: 'Berlin' },
                    { data: 161.3565907, label: 'Brandenburg' },
                    { data: 1651.364985, label: 'Bremen' },
                    { data: 1767.940351, label: 'Hamburg' },
                    { data: 355.2986727, label: 'Hessen' },
                    { data: 120.3522141, label: 'Mecklenburg-Vorpommern' },
                    { data: 302.0087727, label: 'Niedersachsen' },
                    { data: 631.0707179, label: 'Nordrhein-Westfalen' },
                    { data: 298.3276793, label: 'Rheinland-Pfalz' },
                    { data: 509.9839445, label: 'Saarland' },
                    { data: 273.9644926, label: 'Sachsen' },
                    { data: 206.7551122, label: 'Sachsen-Anhalt' },
                    { data: 286.0093365, label: 'Schleswig-Holstein' },
                    { data: 222.2819254, label: 'Thüringen' },
                  ]
                },
              ]
            }
          ]
        },
      },
      decentral: {
        text: 'Auf fast allen Dachflächen von privaten und öffentlichen Gebäuden sind Solarmodule befestigt. Der Eigenverbrauch von Solarstrom wird weiter gestärkt, d.h. Privatmenschen und Firmen produzieren und verbrauchen zunehmend eigenen Strom. Sie teilen aber auch den Strom mit anderen. Dafür gibt es eine intelligente Steuerung der Stromnachfrage. So werden z.B. Elektrofahrzeuge bevorzugt nachgeladen, wenn der Gesamtbedarf an Strom in Deutschland gerade gering ist. Ein bisschen so, wie man früher die Waschmaschine nachts angestellt hat.',
        dataVisual: {
          type: 'map',
          maxData: 1000,
          items: [
            {
              label: '2030',
              items: [
                {
                  label: 'Solar',
                  color: '#893E63',
                  items: [
                    { data: 409.4841065, label: 'Baden-Württemberg' },
                    { data: 329.6379227, label: 'Bayern' },
                    { data: 1903.000472, label: 'Berlin' },
                    { data: 151.2794682, label: 'Brandenburg' },
                    { data: 1495.964066, label: 'Bremen' },
                    { data: 1573.855969, label: 'Hamburg' },
                    { data: 355.2986727, label: 'Hessen' },
                    { data: 112.5197104, label: 'Mecklenburg-Vorpommern' },
                    { data: 298.2268301, label: 'Niedersachsen' },
                    { data: 621.3935702, label: 'Nordrhein-Westfalen' },
                    { data: 301.2137629, label: 'Rheinland-Pfalz' },
                    { data: 509.9839445, label: 'Saarland' },
                    { data: 258.1674172, label: 'Sachsen' },
                    { data: 194.0681097, label: 'Sachsen-Anhalt' },
                    { data: 281.5098792, label: 'Schleswig-Holstein' },
                    { data: 208.6367973, label: 'Thüringen' },
                  ]
                },
              ]
            },
            {
              label: '2045',
              items: [
                {
                  label: 'Solar',
                  color: '#893E63',
                  items: [
                    { data: 835.326424, label: 'Baden-Württemberg' },
                    { data: 649.7736155, label: 'Bayern' },
                    { data: 4603.102646, label: 'Berlin' },
                    { data: 353.5096363, label: 'Brandenburg' },
                    { data: 3621.451968, label: 'Bremen' },
                    { data: 3864.477655, label: 'Hamburg' },
                    { data: 779.1654581, label: 'Hessen' },
                    { data: 263.2403075, label: 'Mecklenburg-Vorpommern' },
                    { data: 662.1124005, label: 'Niedersachsen' },
                    { data: 1383.837516, label: 'Nordrhein-Westfalen' },
                    { data: 653.5139713, label: 'Rheinland-Pfalz' },
                    { data: 1111.006556, label: 'Saarland' },
                    { data: 600.5609639, label: 'Sachsen' },
                    { data: 452.8881532, label: 'Sachsen-Anhalt' },
                    { data: 627.0559035, label: 'Schleswig-Holstein' },
                    { data: 486.392802, label: 'Thüringen' },
                  ]
                },
              ]
            }
          ]
        },
      },
    },
    {
      label: 'Speicher',
      headline: 'Wie wird die Versorgung stabilisiert? ',
      overlay: {
        subline: 'Wie soll der Strom gespeichert werden?',
        text: 'Was geschieht, wenn der Wind nicht weht und die Sonne nicht scheint, also die sogenannte „Dunkelflaute“ einsetzt? Oder umgekehrt - was geschieht, wenn wir zu viel Strom produzieren? Klar ist, es braucht mehr Speicherkapazität, um die schwankende Verfügbarkeit von Strom aus Sonne und Wind auszugleichen. Dass Wind und Sonne zeitgleich aussetzen, kommt tatsächlich selten vor, weshalb es ausreicht, einen Bruchteil der durchschnittlichen Solarproduktion in Speichern vorzuhalten. Wichtige Speichermöglichkeiten sind Heimbatteriespeicher für einzelne Gebäude und <a class="link" data-index="quartierspeicher">Quartiersspeicher</a> für Siedlungen. Für Anwendungen in der Industrie und Fernwärme kann der überschüssige Strom auch in Wärme umgewandelt werden und <a class="link" data-index="zentrale-waermespeicher">zentrale Wärmespeicher</a> verwendet werden, die sehr kostengünstig und unkompliziert sind. Darüber hinaus wird die Umwandlung von Strom zu Wasserstoff und zurück wesentlich für die Überbrückung von Dunkelflauten sein. Das hohe Niveau der deutschen Versorgungssicherheit kann damit erhalten bleiben. ',
      },
      central: {
        text: 'In einer zentralen Welt werden weniger Speicher in den Haushalten aufgebaut, da die Erzeugung weniger stark verteilt ist. Dafür können mehr <a class="link" data-index="zentrale-waermespeicher">zentrale Wärmespeicher</a> zum Einsatz kommen, um kostengünstig Strom in Wärme umzuwandeln und somit die Energie zu speichern. Zum Beispiel kann die Industrie sehr gut Gebrauch von diesen Speichern machen. Große <a class="link" data-index="quartierspeicher">Quartiersspeicher</a> können die Stromversorgung von Stadtvierteln unterstützen. Sie werden erst spät wichtig, um 2045.',
        dataVisual: {
          type: 'batteries',
          items: [
            {
              label: 'Jetzt',
              items: [
                { label: 'Heimbatterie-speicher', data: 2627 },
                { label: 'Quartiersbatterie-speicher', data: 550 },
                { label: 'Zentrale Wärmespeicher', data: 18470 },
              ]
            },
            {
              label: '2030',
              items: [
                { label: 'Heimbatterie-speicher', data: 22151 },
                { label: 'Quartiersbatterie-speicher', data: 550 },
                { label: 'Zentrale Wärmespeicher', data: 437621 },
              ]
            },
            {
              label: '2045',
              items: [
                { label: 'Heimbatterie-speicher', data: 45122 },
                { label: 'Quartiersbatterie-speicher', data: 57761 },
                { label: 'Zentrale Wärmespeicher', data: 687378 },
              ]
            },
          ]
        },
      },
      decentral: {
        text: 'In einer dezentralen Welt werden mehr <a class="link" data-index="heimspeicher">Heimbatteriespeicher</a> direkt in den Haushalten installiert. Durch die Heimbatteriespeicher können private Stromproduzenten ihren Strom zwischenspeichern, um den Strom bei Bedarf selbst zu verwenden. Oder um Strom für die Allgemeinheit bereit zu halten und ins <a class="link" data-index="stromnetz">Stromnetz</a> einzuspeisen, wenn großer Bedarf besteht (z.B. durch Industrie). Zentrale Wärmespeicher sind eine zusätzliche Methode, um Strom kostengünstig und mit einfachen Mitteln in Wärme umzuwandeln und damit die Energie zu speichern. <a class="link" data-index="quartierspeicher">Quartiersspeicher</a> sind kaum relevant. ',
        dataVisual: {
          type: 'batteries',
          items: [
            {
              label: 'Jetzt',
              items: [
                { label: 'Heimbatterie-speicher', data: 2627 },
                { label: 'Quartiersbatterie-speicher', data: 550 },
                { label: 'Zentrale Wärmespeicher', data: 18470 },
              ]
            },
            {
              label: '2030',
              items: [
                { label: 'Heimbatterie-speicher', data: 38765 },
                { label: 'Quartiersbatterie-speicher', data: 550 },
                { label: 'Zentrale Wärmespeicher', data: 269352 },
              ]
            },
            {
              label: '2045',
              items: [
                { label: 'Heimbatterie-speicher', data: 101525 },
                { label: 'Quartiersbatterie-speicher', data: 550 },
                { label: 'Zentrale Wärmespeicher', data: 269585 },
              ]
            },
          ]
        },
      },
    },
    {
      label: 'Netz',
      nextLabel: 'Stellschrauben',
      nextLink: 'screw-overview',
      headline: 'Wie kommt der Strom dorthin?',
      overlay: {
        subline: 'Wie kommt der Strom dorthin?',
        text: 'Bis der Strom in Ihrer hauseigenen Steckdose landet, legt er nicht selten einen langen Weg durch das <a class="link" data-index="stromnetz">Stromnetz</a> zurück. Die Veränderungen an den Stromnetztrassen unterscheiden sich für beide Welten weniger stark, als man vermuten könnte. Mehrere Netzausbaumaßnahmen für ein <a class="link" data-index="stromnetz">Stromnetz</a> im Jahr 2030 wurden bereits nach einem Prozess mit breiter Konsultation der Öffentlichkeit genehmigt. Dieses ‚Stromnetz 2030‘ befindet sich bereits im Bau und beinhaltet unter anderem vier große „Stromautobahnen“ von Nord nach Süd. Wenn unser Strombedarf bis 2045 weiter ansteigt, brauchen wir keine neuen Trassen, sondern ersetzen nach Bedarf existierende Masten durch höhere und leistungsfähigere. Voraussetzung ist, dass im Jahr 2045 der Strom, der an den großen <a class="link" data-index="off-shore-on-shore">Offshore-Anlagen</a> an Ost- und Nordsee produziert wird, in Wasserstoff umgewandelt wird und dann als solcher durch die Republik transportiert wird.',
      },
      central: {
        text: 'Das Strom-<a class="link" data-index="uebertragungsnetz">Übertragungsnetz</a> wird ausgebaut, indem ab 2030 existierende Strommasten bis 2045 durch höhere Masten mit mehr Leitungen austauscht werden. Diese verstärkten Stromautobahnen bringen den Strom von den zentralen Erzeugungsanlagen in die Regionen, in denen nicht so viel erneuerbarer Strom zur Verfügung steht. Es ergibt sich ein geringerer Digitalisierungsbedarf auf den letzten Metern der Stromversorgung. Kurz – die Netze müssen weniger intelligent sein, aber die großen Verbindungen von Nord nach Süd dafür leistungsstärker.',
        image: 'world-map-central'
      },
      decentral: {
        text: 'Die letzte Meile des <a class="link" data-index="stromnetz">Stromnetzes</a>, die die Verbraucher:innen mit dem Gesamtnetz verbindet, muss „intelligent“ sein: Da Dachsolaranlagen in großem Stil die deutsche Energieversorgung unterstützen, übernehmen digitale Anwendungen die „intelligente“ Steuerung der <a class="link" data-index="stromspeicher">Stromspeicherung</a> und - einspeisung. Diese digitalen Anwendungen müssen hohen Datenschutz garantieren, da sie bezüglich  Strom auf Verbrauch- und Produktionsmuster zugreifen, um eine stabile Versorgung für alle zu  garantieren. Hingegen ist der Bedarf an höheren und leistungsfähigeren Strommasten und Stromleitungen gering, da mehr Strom vor Ort produziert und verbraucht wird.  ',
        image: 'world-map-decentral',
      },
    }
  ]
}
