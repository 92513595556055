export default {
  introText: {
    headline: 'Energieproduktion - heute',
    text: 'Etwa 85 % des Treibhausgasausstoßes in Deutschland gehen auf die Energieversorgung zurück. Davon entsteht fast die Hälfte bei der Produktion von Strom und Wärme. Die andere Hälfte entfällt auf Energieverbräuche bei Verkehr, Industrieprozesse und Gebäude. <br /> <br /> Wollen wir das Klima schützen, müssen wir den Treibhausgasausstoß verringern. Damit führt kein Weg an einer erneuerbaren Energieversorgung vorbei. ',
  },
  fossil: {
    overline: '',
    headline: 'Neuer Strom',
    text: 'Solar- und Windkraftanlagen sind im Moment die größten Hoffnungsträger, günstig und im großen Stil erneuerbaren Strom zu gewinnen. <br /> Aber es geht nicht nur darum, unseren jetzigen Strom ‚grün‘ zu machen. Unsere gesamte Energieversorgung, auch in Verkehr, Industrie und Gebäuden muss so weit wie möglich auf Strom umgestellt werden. Dies bedeutet, dass wir auch viel mehr erneuerbaren Strom in Zukunft brauchen. Strom rückt also ins Zentrum der zukünftigen Energieversorgung.',
  },
  solar: {
    text: 'Die Frage ist, wie wir die Stromversorgung und das Energiesystem gemeinsam umgestalten wollen. Je nachdem, wie wir uns entscheiden, ergeben sich unterschiedliche Welten. Zwei durchaus sehr unterschiedlich ausgeprägte Welten stellen wir Ihnen hier vor.',
    buttonBottom: 'Energiewelten entdecken',
    buttonBottomLink: '/world',
    buttonBottomBackLink: '/onboarding',
    buttonBottomBack: 'Zum Anfang',
  },
}
