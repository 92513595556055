
























































import { Component, Prop, Vue } from 'vue-property-decorator'
import imageFossil from '../assets/images/energy-home-fossil.svg'
import imageSolar from '../assets/images/solar.svg'
import imageHomeIntro from '../assets/images/energy-home-intro.svg'

@Component({
})
export default class HomeEnergy extends Vue {
  @Prop() data!: {
    introText: {
      overline: string
      headline: string
      text: string
    }
    fossil: {
      overline: string
      headline: string
      text: string
    }
    solar: {
      overline: string
      headline: string
      text: string
      buttonBottom: string
      buttonBottomLink: string
      buttonBottomBackLink: string
      buttonBottomBack: string
    },
  }

  imageFossil = imageFossil
  imageSolar = imageSolar
  imageHomeIntro = imageHomeIntro

  isOverlayDecentralOpen = false
  isOverlayCentralOpen = false
}
