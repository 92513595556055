






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})
export default class AdviceIntro extends Vue {
  @Prop() data!: {
    tabsLabel: string
    dataLabel: string
    headline: string
    text: string
    button: string
    buttonLink: string
    items: {
      label: string
      data: number
    }[]
  }

  tabIndex = 0

  get tabs(): string[] {
    return this.data.items.map((i) => i.label)
  }

  get currentItem() {
    return this.data.items[this.tabIndex]
  }

  get dataClassname() {
    switch (this.tabIndex) {
      case 0: {
        return 'small'
      }
      case 1: {
        return 'medium'
      }
      default: {
        return 'big'
      }
    }
  }
}
